@import "src/themes/mojito/styles/index.scss";
.stats-box {
    margin-bottom: 0 !important;
    max-width: 100%;
    padding: 8px !important;

    @include sm {
        padding: 16px !important;
    }
    svg {
        color: getvar($colors, "primary", "500")
    }
}