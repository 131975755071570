@import "src/themes/mojito/styles/index.scss";
.Chip {
  min-width: 50px;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  color: getvar($colors, "primary", "600");
  background-color: getvar($colors, "primary", "100");
  border: 1px solid;
  border-color: getvar($colors, "primary", "600");
  border-radius: 16px;
  white-space: nowrap;
  outline: 0;
  text-decoration: none;
  padding: 0 space(0.375);
  vertical-align: middle;
  box-sizing: border-box;

  @include sm {
    padding: 0 space(0.625);
  }

  .text {
    font-weight: 400;
    line-height: 0px;
    margin-top: 0 !important;
    display: flex;

    &__icon {
      display: flex;
      align-items: center;

      &--left {
        margin-right: space(0.5);
      }

      &--right {
        margin-left: space(0.5);
      }

      svg {
        transform: scale(1.3);
      }
    }
  }

  &--medium {
    font-size: 12px;
    padding: space(0.8) space(0.625);
  }

  &--success {
    border-color: getvar($colors, "success", "600");
    color: getvar($colors, "success", "600");
    background-color: getvar($colors, "success", "100");
  }
  &--danger {
    border-color: getvar($colors, "error", "600");
    color: getvar($colors, "error", "600");
    background-color: getvar($colors, "error", "100");
  }
  &--warning {
    border-color: getvar($colors, "warning", "600");
    color: getvar($colors, "warning", "600");
    background-color: getvar($colors, "warning", "100");
  }
  &--inactive {
    border-color: getvar($colors, "neutral", "600");
    color: getvar($colors, "neutral", "600");
    background-color: getvar($colors, "neutral", "100");
  }

  &--filled,
  &--outlined {
    .text {
      font-weight: 800 !important;
      line-height: 0px;
      display: flex;
      justify-content: center;
    }
  }

  &--filled.Chip--success {
    color: white;
    background-color: getvar($colors, "primary-green", "500");
  }
  &--filled.Chip--warning {
    color: white;
    background-color: getvar($colors, "warning", "600");
  }

  &--outlined {
    background-color: white;
    border: 1px solid;
    color: getvar($colors, "primary", "500");
  }

  &--outlined.Chip--success {
    color: getvar($colors, "primary-green", "500");
    background-color: getvar($colors, "neutral", "50");
    border-color: getvar($colors, "primary-green", "500");
  }

  &--fit-content {
    width: fit-content;
  }

  &--mobile {
    @include sm {
      display: none;
    }
  }

  &--tablet {
    display: none;
    @include sm {
      display: inline-flex;
    }
  }
}
