@import "src/themes/mojito/styles/index.scss";
.default-selected {
  background-color: getvar($colors, "primary", "400") !important;
  color: white;

  &:hover {
    background-color: getvar($colors, "primary", "400") !important;
    color: white;
  }
}
